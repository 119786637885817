.btn-group {
  margin: 15px 0;

  .dropdown-menu {
    li {
      padding: 5px 10px;
      a {
        &.btn {
          color: $white;

          &:hover {
            &.btn-warning {
              background-color: rgb(182, 163, 56);
              border-color: rgb(155, 138, 48);
            }
            &.btn-danger {
              background-color: rgb(149, 65, 32);
              border-color: rgb(120, 52, 26);
            }
          }
        }
      }
    }
  }
}