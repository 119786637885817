@media screen and (min-width: 1024px) {
  #images-container {
    .image-container {
      .image {
        margin-bottom: 15px;

        .thumbnail-container {
          height: 300px;
          padding-bottom: 15px;
          text-align: center;
          display: block;

          .thumbnail {
            height: inherit;
            margin: 0 auto;
          }
        }
      }

      .buttons {
        padding-bottom: 15px;

        .btn-group {
          width: 100%;

          .btn {
            width: calc(100% / 5);
          }
        }
      }
    }
  }

  #image-filter-nav,
  #image-filter-nav-header {
    padding-top: 13px;
  }
}