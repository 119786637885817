#images-container {
  .image-container {
    font-size: 12px;

    p {
      margin: 0;
    }

    hr {
      margin: 5px 0 10px;
      border-top: 1px solid rgb(221, 221, 221);
    }

    .table {
      background-color: rgb(255, 255, 255);
    }

    .image-approved {
      border: 1px solid rgb(223, 240, 216);
    }
    .image-rejected {
      border: 1px solid rgb(242, 222, 222);
    }

    .image {
      .thumbnail-container {
        padding-bottom: 0;
        overflow: hidden;

        .thumbnail {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
        }
      }
    }

    .buttons {
      padding-bottom: 0;

      .btn-group {
        .btn {
          width: calc(100% / 6);
        }
      }
    }
  }
}