.dropdown {
  .dropdown-menu {
    &[data-keep-open=true] {
      width: 200px;
      height: 530px;
      max-height: none;
      overflow: auto;

      .top,
      .bottom {
        height: 50px;
      }

      .top {
        margin: 5px 0;

        .btn {
          width: calc(100% - 30px);
        }
      }

      .middle {
        max-height: 400px;
        overflow-x: hidden;
        overflow-y: scroll;

        ul {
          padding: 0 15px;
          list-style: none;
        }
      }

      .bottom {
        padding: 0 15px;
        font-size: 15px;
        line-height: 55px;
      }
    }
  }
}