@media screen and (max-width: 767px) {
  .btn {
    margin-bottom: 15px;
  }

  .modal-footer {
    .btn {
      margin-bottom: 5px;
    }
  }

  .btn-group {
    width: 100%;
    float: none;

    .btn {
      width: calc(100% / 5);
    }
  }

  #form-login {
    .btn {
      &.btn-info {
        margin-bottom: 0;
      }

      &.btn-primary {
        width: 100%;
        margin-top: 10px;
      }
    }
  }

  #images-container {
    .image-container {
      img {
        max-width: 100%;
      }
    }
  }

  .navbar {
    &.navbar-static-top {
      height: 174px;
    }
  }
}