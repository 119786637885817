html, body, #app {
  background: $drf-grey;
}

#app {
  margin-bottom: 60px;
}

textarea {
  resize: none
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $drf-darkBlue;
}

h1,
h2,
h3,
h4,
h5,
h6,
.btn {
  font-family: "Bebas Neue", sans-serif;
}

.btn {
  &.disabled {
    opacity: .2;
  }
}

.full-width {
  width: 100%;
}

.approved {
  background: $approved;
  color: $drf-grey;
}

.rejected {
  background: $rejected;
  color: $drf-grey;
}

#site-logo {
  padding: 7px 0;
  max-width: 100%;
  // improve image quality when displayed smaller
  backface-visibility: hidden;
  transform: translateZ(0);
}

.btn {
  padding-top: 10px;

  &.btn-info,
  &.btn-info:focus,
  &.btn-info:active {
    background-color: $drf-lightBlue;
    border-color: $drf-lightBlue20;
  }

  &.btn-primary,
  &.btn-success {
    background-color: $drf-darkBlue;
    border-color: $drf-darkBlue;

    &:focus,
    &:active {
      background-color: $drf-darkBlue;
      border-color: $drf-darkBlue;
    }
  }

  i.fa {
    padding-left: 2px;
  }
}

.modal {
  .modal-title {
    font-size: 30px;
  }
}

.alert {
  &.alert-success {
    color: $drf-darkBlue;
    background-color: $drf-lightBlue20;
    border-color: $drf-lightBlue;
  }
  &.alert-danger {
    color: $drf-lightBlue;
    background-color: $drf-darkBlue;
    border-color: $drf-darkBlue;
  }
}

.text-break-hard {
  word-break: break-all;
  word-wrap: break-word;
}

.checkbox {
  label {
    input {
      &[type=checkbox] {
        margin-top: 10px;
      }
    }
  }
}

#aldi-logo {
  max-width: 60px;
  padding-top: 5px;
}

.panel {
  &.panel-danger {
    color: $darkRed;
  }
}

.text-portal {
  font-family: "Bebas Neue", sans-serif;
  font-size: 26px;
  color: $drf-darkBlue !important;
  text-transform: uppercase;
}

.dropdown-menu {
  & > li {
    & > label {
      display: block;
      padding: 3px 20px;
      clear: both;
      font-weight: normal;
      line-height: 1.6;
      white-space: nowrap;
    }
  }
}

.navbar {
  &.navbar-static-top {
    .navbar-header {
      .navbar-brand {
        img {
          max-width: 100%;
          max-height: 100%;
          display: inline-block;
          vertical-align: baseline;
        }
      }
    }
  }

  &.navbar-fixed {
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    z-index: 999;
  }
}

.navbar-nav {
  & > li {
    & > .dropdown-menu {
      max-height: 400px;
      overflow-x: hidden;
      overflow-y: scroll;
    }
  }
}

#image-filter-nav {
  .navbar-nav {
    .dropdown {
      a {
        background-color: rgb(78, 139, 200);
        color: rgb(255, 255, 255);
        border-radius: 5px;
        margin: 0 10px;
      }
    }
  }
}
.navbar-search-form {
  input,
  button {
    height: 32px;
    line-height: 32px;
  }

  button {
    padding: 0 10px;
  }
}