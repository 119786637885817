@media screen and (min-width: 768px) {
  #site-logo {
    max-width: 50%;
  }

  #save-approval {
    margin: 15px 0;
    float: right;
  }

  .navbar {
    &.navbar-static-top {
      height: 125px;

      .navbar-right {
        margin-right: 5px;

        .fa {
          &.fa-user {
            font-size: 1.4em;
          }
        }
      }
    }
  }
  .popover {
    max-width: 75%;

    .upload-info-popover-table {
      tr {
        td {
          padding: 5px;
          vertical-align: top;
        }
      }
    }
  }
}