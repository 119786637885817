// Body
$body-bg: rgb(241, 241, 241);
$white: rgb(255, 255, 255);
$darkRed: rgb(169, 68, 66);

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: rgb(41, 151, 209);
$brand-info: rgb(142, 180, 203);
$brand-success: rgb(42, 178, 123);
$brand-warning: rgb(203, 185, 86);
$brand-danger: rgb(191, 83, 41);

// Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 18px;
$line-height-base: 1.6;
$text-color: rgb(99, 107, 111);

// Navbar
$navbar-default-bg: $white;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: $white;

// DFR colors
$drf-darkBlue: rgb(0, 44, 90);
$drf-lightBlue: rgb(79, 139, 200);
$drf-lightBlue20: rgb(222, 240, 250);
$drf-grey: rgb(241, 241, 241);

// DFR fonts
$drf-font: "bebas nue";

// status
$approved: $brand-success;
$rejected: $brand-danger;